.project {
  width: 80%;
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-reverse {
  width: 80%;
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.__img_wrapper {
  width: 60em;
}
.__img_wrapper > img {
  /* width: 100%; */
  /* margin: 10em; */
  width: 35em;
  height: 20em;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}
.__content_wrapper {
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}
.title {
  font-size: 1.5rem;
  margin: 0;
}
.description {
  font-size: 1rem;
  width: 60%;
}
@media (max-width: 1024px) {
  .project {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .__content_wrapper {
    width: 50%;
  }
  ._img {
    margin: 2em;
    border-color: rebeccapurple;
  }
 
}
@media (max-width: 768px) {
  .project {
    margin-left: 4em;
    margin-right: 4em;
    flex-direction: column;
  }
 
  .__content_wrapper {
    width: 70%;
  }
  ._img {
    width: 15em;
    height: 12em;
    background-color: aqua;
    padding: 10;
    border-color: rebeccapurple;
  }
}